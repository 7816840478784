import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';

const statusChoices = [
  { id: 'CUSTOMER_PENDING_REVIEW', name: 'Pending Customer Review' },
  { id: 'CUSTOMER_ACCEPTED', name: 'Customer Accepted' },
  { id: 'CUSTOMER_REJECTED', name: 'Customer Rejected' },
  { id: 'CUSTOMER_PARTIALLY_ACCEPTED', name: 'Customer Partially Accepted' },
];

const QuotationFilter = props => (
  <Filter {...props}>
    <TextInput source="q" label="Search" variant="outlined" alwaysOn />

    <TextInput
      source="email"
      label="Search By Username"
      alwaysOn
      variant="outlined"
    />

    <SelectInput
      source="status"
      label="Status"
      choices={statusChoices}
      optionText="name"
      optionValue="id"
      alwaysOn
      variant="outlined"
      emptyText="All"
      emptyValue=""
    />
  </Filter>
);

export default QuotationFilter;
