import { Chip } from '@mui/material';
import { ENQUIRY_STATUS } from '../../constants';
import currencyLocales from '../content/currencyLocales.json';

export const getFormFieldText = (formFields, fieldId, textType) => {
  const field = formFields?.find(field => field?.id === fieldId);
  return field ? field[textType] : '';
};

export const formatStatusName = str => {
  return str
    ?.toLowerCase()
    ?.split('_')
    ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(' ');
};

export const formatCurrency = (amount, currencyCode = 'INR') => {
  const locale = currencyLocales[currencyCode] || 'en-IN';

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  })?.format(amount);
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const EnquiryStatus = ({ status }) => {
  const getStatusColor = status => {
    switch (status) {
      case ENQUIRY_STATUS.SUBMITTED:
        return '#FFA000'; // Amber
      case ENQUIRY_STATUS.QUOTATION_GENERATED:
        return '#2196F3'; // Blue
      case ENQUIRY_STATUS.INVOICE_PENDING:
        return '#FF9800'; // Orange
      case ENQUIRY_STATUS.INVOICE_GENERATED:
        return '#4CAF50'; // Green
      case ENQUIRY_STATUS.ADVANCE_PAYMENT_INITIATED:
        return '#9C27B0'; // Purple
      case ENQUIRY_STATUS.ORDER_IN_PROGRESS:
        return '#3F51B5'; // Indigo
      case ENQUIRY_STATUS.DELIVERED_PENDING_PAYMENT:
        return '#00BCD4'; // Cyan
      case ENQUIRY_STATUS.FINAL_PAYMENT_INITIATED:
        return '#009688'; // Teal
      case ENQUIRY_STATUS.CLOSED:
        return '#795548'; // Brown
      case ENQUIRY_STATUS.COMPLETED:
        return '#4CAF50'; // Green
      default:
        return '#9E9E9E'; // Grey
    }
  };

  return (
    <Chip
      label={formatStatusName(status)}
      style={{
        backgroundColor: getStatusColor(status),
        color: '#FFFFFF',
      }}
    />
  );
};
