import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import {
  useNotify,
  useRefresh,
  Button,
  useDataProvider,
  Link,
  useGetOne,
} from 'react-admin';

import { InvoiceFormValidator } from '../../../common/formik-validator/InvoiceFormik';

import styles from './styles.module.css';

const InvoiceActions = ({ record, enquiryData, formFields }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { data: currency } = useGetOne('currencies', {
    id: record?.currency_id,
  });

  const formik = useFormik({
    initialValues: {
      invoiceNumber: '',
      invoiceAmount: '',
      billTo: '',
      shipTo: '',
      file: null,
    },
    validate: values => {
      const errors = InvoiceFormValidator(values, openUpload, formFields);
      if (openUpload && !values.file) {
        errors.file = 'Please select a file';
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (openConfirm) {
        await handleGenerateInvoice(values, setSubmitting);
      } else if (openUpload) {
        await handleUploadInvoice(values, setSubmitting);
      }
    },
  });

  const handleGenerateInvoice = async (values, setSubmitting) => {
    try {
      await dataProvider.generateInvoice(
        record?.id,
        values.billTo,
        values.shipTo,
        currency?.code
      );
      notify('Invoice generated successfully', 'success');
      refresh();
      setOpenConfirm(false);
    } catch (error) {
      notify(`Error generating invoice: ${error.message}`, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  const handleUploadInvoice = async (values, setSubmitting) => {
    try {
      await dataProvider.uploadInvoice(
        record?.id,
        values.invoiceNumber,
        values.invoiceAmount,
        values.billTo,
        values.shipTo,
        values.file
      );
      notify('Invoice uploaded successfully', 'success');
      refresh();
      setOpenUpload(false);
    } catch (error) {
      notify(`Error uploading invoice: ${error.message}`, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedFile(file);
    formik.setFieldValue('file', file);
  };

  const renderTextField = (name, label, type = 'text', multiline = false) => {
    return (
      <TextField
        fullWidth
        id={name}
        name={name}
        label={label}
        type={type}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        margin="normal"
        variant="outlined"
      />
    );
  };

  if (enquiryData?.status === 'INVOICE_PENDING') {
    return (
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => setOpenConfirm(true)}
          label="Generate Invoice"
          variant="contained"
          className={styles.button}
        />

        <Button
          onClick={() => setOpenUpload(true)}
          label="Upload Invoice"
          variant="outlined"
          className={styles.button}
        />

        {/* Generate Invoice Dialog */}
        <Dialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          classes={{ paper: styles.modalContainer }}
          aria-labelledby="confirm-generate-invoice-dialog"
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle
              className={styles.dialogTitle}
              id="confirm-generate-invoice-dialog"
            >
              Generate Invoice
            </DialogTitle>

            <DialogContent>
              <DialogContentText className={styles.dialogContent}>
                Please provide the billing and shipping details to generate the
                invoice.
              </DialogContentText>
              {renderTextField('billTo', 'Bill To', 'text', true)}
              {renderTextField('shipTo', 'Ship To', 'text', true)}
            </DialogContent>

            <DialogActions className={styles.dialogActions}>
              <Button
                onClick={() => setOpenConfirm(false)}
                color="primary"
                className={`${styles.button} ${styles.cancelButton}`}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                color="primary"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.dirty
                }
                className={`${styles.button} ${styles.confirmButton}`}
                variant="contained"
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  'Generate Invoice'
                )}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {/* Upload Invoice Dialog */}
        <Dialog
          open={openUpload}
          onClose={() => setOpenUpload(false)}
          maxWidth="sm"
          fullWidth
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle
              className={styles.dialogTitle}
              id="upload-invoice-dialog"
            >
              Upload Invoice
            </DialogTitle>

            <DialogContent>
              <Box component="div" sx={{ mt: 2 }}>
                {renderTextField('invoiceNumber', 'Invoice Number')}
                {renderTextField(
                  'invoiceAmount',
                  'Total Invoice Amount',
                  'number'
                )}
                {renderTextField('billTo', 'Bill To', 'text', true)}
                {renderTextField('shipTo', 'Ship To', 'text', true)}

                <Box sx={{ mt: 2, mb: 1 }}>
                  <input
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    className={styles.fileInput}
                    onChange={handleFileChange}
                  />

                  <label
                    htmlFor="raised-button-file"
                    className={styles.fileInputLabel}
                  >
                    <Button variant="outlined" component="span" fullWidth>
                      Choose PDF File
                    </Button>
                  </label>
                </Box>
                {selectedFile && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Selected file: {selectedFile.name}
                  </Typography>
                )}
                {formik.touched.file && formik.errors.file && (
                  <Typography color="error" variant="caption">
                    {formik.errors.file}
                  </Typography>
                )}
              </Box>
            </DialogContent>

            <DialogActions sx={{ px: 3, pb: 2 }}>
              <Button onClick={() => setOpenUpload(false)} color="primary">
                Cancel
              </Button>

              <Button
                type="submit"
                color="primary"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.dirty
                }
                className={`${styles.button} ${styles.confirmButton}`}
                variant="contained"
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  'Upload Invoice'
                )}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  } else if (record?.invoice_id) {
    return (
      <Link
        to={`/invoices/${record.invoice_id}/show`}
        className={styles.linkButton}
      >
        <Typography variant="button">View Invoice</Typography>
      </Link>
    );
  } else {
    return null;
  }
};

export default InvoiceActions;
