import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from '@mui/material';
import { getShippingDocumentTypes } from 'supabase-connect';

import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Constants
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];

const getFileNameFromUrl = url => {
  try {
    const fileName = url?.split('/')?.pop();
    return fileName
      ? fileName.replace(/^[^_]+_[^_]+_/, '').replace(/%20/g, ' ')
      : 'Unnamed document';
  } catch {
    return 'Unnamed document';
  }
};

const DocumentUploader = ({
  fetchDocuments,
  uploadDocument,
  deleteDocument,
  notify,
  loadingDocuments,
  documents,
  isDisabled,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      try {
        const types = await getShippingDocumentTypes();
        setDocumentTypes(types);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching document types:', error);
        notify('Error fetching document types', { type: 'error' });
      }
    };

    fetchDocumentTypes();
  }, [notify]);

  const handleFileSelect = event => {
    if (isDisabled) {
      return;
    }

    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!ALLOWED_FILE_TYPES.includes(fileExtension)) {
        notify(`File ${file.name} has an unsupported file type`, {
          type: 'warning',
        });
        resetFileInput();
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        notify(`File ${file.name} is larger than 5MB`, { type: 'warning' });
        resetFileInput();
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleDocumentTypeChange = event => {
    if (isDisabled) {
      return;
    }

    setSelectedDocumentType(event.target.value);
  };

  const handleDocumentUpload = async () => {
    if (isDisabled) {
      return;
    }

    if (!selectedFile || !selectedDocumentType) {
      notify('Please select both a file and document type', {
        type: 'warning',
      });
      return;
    }

    setLoadingUpload(true);

    try {
      await uploadDocument(selectedFile, selectedDocumentType);
      notify('Document uploaded successfully!', { type: 'success' });
      await fetchDocuments();
      resetFileInput();
    } catch (error) {
      notify(`Error uploading document: ${error.message}`, { type: 'error' });
    } finally {
      setLoadingUpload(false);
    }
  };

  const handleDocumentDelete = async documentId => {
    if (isDisabled) {
      return;
    }

    try {
      await deleteDocument(documentId);
      notify('Document deleted successfully!', { type: 'success' });
      await fetchDocuments();
    } catch (error) {
      notify(`Error deleting document: ${error.message}`, { type: 'error' });
    }
  };

  const resetFileInput = () => {
    setSelectedFile(null);
    setSelectedDocumentType('');
    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
    }
  };

  const getDocumentIcon = fileName => {
    const extension = fileName?.split('.')?.pop()?.toLowerCase() ?? '';
    switch (extension) {
      case 'pdf':
        return <PictureAsPdfIcon />;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <ImageIcon />;
      default:
        return <DescriptionIcon />;
    }
  };

  return (
    <>
      <Box mb={4}>
        <Typography variant="subtitle1" gutterBottom>
          Shipping Documents
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <input
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              style={{ display: 'none' }}
              id="document-upload"
              type="file"
              onChange={handleFileSelect}
              ref={fileInputRef}
              disabled={isDisabled}
            />

            <label htmlFor="document-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUploadIcon sx={{ mr: 1 }} />}
                disabled={loadingUpload || isDisabled}
              >
                Choose File
              </Button>
            </label>
          </Grid>

          <Grid item>
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel>Document Type</InputLabel>

              <Select
                value={selectedDocumentType}
                onChange={handleDocumentTypeChange}
                label="Document Type"
                disabled={isDisabled}
              >
                {documentTypes?.map(type => (
                  <MenuItem key={type?.id} value={type?.id}>
                    {type?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDocumentUpload}
              disabled={
                loadingUpload ||
                !selectedFile ||
                !selectedDocumentType ||
                isDisabled
              }
            >
              {loadingUpload ? <CircularProgress size={24} /> : 'Upload'}
            </Button>
          </Grid>
        </Grid>

        {selectedFile && (
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              Selected file: {selectedFile.name}
            </Typography>
          </Box>
        )}
      </Box>

      {loadingDocuments ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : documents?.length > 0 ? (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '5%' }}>Type</TableCell>

                <TableCell sx={{ width: '40%' }}>Document Name</TableCell>

                <TableCell sx={{ width: '40%' }}>Document Type</TableCell>

                <TableCell
                  align="right"
                  sx={{
                    width: '15%',
                    pr: 4,
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {documents?.map(document => (
                <TableRow key={document?.id} hover>
                  <TableCell>
                    {getDocumentIcon(document?.document_url)}
                  </TableCell>

                  <TableCell
                    sx={{
                      maxWidth: '300px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {getFileNameFromUrl(document?.document_url)}
                  </TableCell>

                  <TableCell>
                    {documentTypes?.find(
                      type => type?.id === document?.document_type_id
                    )?.label || 'Unknown'}
                  </TableCell>

                  <TableCell align="right">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton
                          size="small"
                          href={document?.document_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={() => handleDocumentDelete(document?.id)}
                          color="error"
                          disabled={isDisabled}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body2" color="textSecondary" align="center">
          No documents uploaded yet.
        </Typography>
      )}
    </>
  );
};

export default DocumentUploader;
