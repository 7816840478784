import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ShowButton,
  useListContext,
  TopToolbar,
  ExportButton,
  Button,
  useGetOne,
  useRedirect,
  FunctionField,
} from 'react-admin';
import { DATE_FORMAT_OPTIONS } from '../../../constants';
import { formatCurrency, formatStatusName } from '../../../common/helpers';
import { FormatStatusField } from '../../../components/common-component/FormatStatusField';

import QuotationFilter from './QuotationFilter';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import styles from './styles.module.css';

const ListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

const SortableButton = ({ field, label }) => {
  const { setSort, sort } = useListContext();

  const handleSort = () => {
    const newOrder =
      sort.field === field && sort.order === 'ASC' ? 'DESC' : 'ASC';
    setSort({ field, order: newOrder });
  };

  const isActive = sort.field === field;
  const order = isActive ? sort.order : null;

  return (
    <Button onClick={handleSort} className={styles.sortable_button}>
      <span>{label}</span>
      {isActive && (
        <span
          className={`${styles.sort_indicator} ${styles[order.toLowerCase()]}`}
        >
          {order === 'ASC' ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <ArrowDownwardIcon fontSize="small" />
          )}
        </span>
      )}
    </Button>
  );
};

const QuotationCard = ({ record }) => {
  const { data: userDetails } = useGetOne('enquiries', {
    id: record.enquiry_id,
  });

  const redirect = useRedirect();

  const handleClick = () => {
    redirect('show', 'quotations', record?.id);
  };

  return (
    <div className={styles.quotation_card} onClick={handleClick}>
      <div className={styles.quotation_field}>
        <span className={styles.field_label}>Quotation Number:</span>

        <span className={styles.field_value}>{record.quotation_number}</span>
      </div>

      <div className={styles.quotation_field}>
        <span className={styles.field_label}>Enquiry Number:</span>

        <span className={styles.field_value}>
          {userDetails?.enquiry_number}
        </span>
      </div>
      <div className={styles.quotation_field}>
        <span className={styles.field_label}>Status:</span>

        <span className={styles.field_value}>
          {formatStatusName(record.status)}
        </span>
      </div>
      <div className={styles.quotation_field}>
        <span className={styles.field_label}>Created At:</span>

        <span className={styles.field_value}>
          {new Date(record.created_at).toLocaleString(
            'en-IN',
            DATE_FORMAT_OPTIONS
          )}
        </span>
      </div>
    </div>
  );
};

const MobileList = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) return null;

  return (
    <div className={styles.mobile_list}>
      <div className={styles.sort_buttons}>
        <SortableButton field="quotation_number" label="Quotation" />

        <SortableButton field="status" label="Status" />

        <SortableButton field="created_at" label="Date" />
      </div>

      {data.map(record => (
        <QuotationCard key={record.id} record={record} />
      ))}
    </div>
  );
};

// Main QuotationList component
export const QuotationList = props => (
  <List
    {...props}
    filters={<QuotationFilter />}
    actions={<ListActions />}
    className={styles.quotation_list}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <div className={styles.desktop_view}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="quotation_number" label="Quotation Number" />

        <ReferenceField
          source="enquiry_id"
          reference="enquiries"
          label="Enquiry Number"
        >
          <TextField source="enquiry_number" />
        </ReferenceField>

        <FunctionField
          label="User Email"
          render={record => record.enquiries?.user_details?.email || ''}
        />

        <FunctionField
          label="Quotation Amount"
          render={record => {
            const formattedAmount = formatCurrency(
              record?.quotation_amount,
              record?.currency_id?.code
            );
            return formattedAmount;
          }}
        />

        <FormatStatusField source="status" />

        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={DATE_FORMAT_OPTIONS}
        />

        <ShowButton label="SHOW" />
      </Datagrid>
    </div>

    <div className={styles.mobile_view}>
      <MobileList />
    </div>
  </List>
);
