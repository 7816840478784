import React, { useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNotify, useDataProvider, useRefresh } from 'react-admin';
import DocumentUploader from '../common-component/DocumentUploader';

const ShippingDocuments = ({ shippingId, userId, enquiryId, isDisabled }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const fetchDocuments = useCallback(async () => {
    if (!shippingId) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await dataProvider.getShippingDocuments(shippingId);
      if (response?.data) {
        setDocuments(response.data);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      setError(error?.message ?? 'Unknown error');
      notify(`Error fetching documents: ${error?.message ?? 'Unknown error'}`, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [dataProvider, shippingId, notify]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const uploadDocument = async (file, documentTypeId) => {
    if (isDisabled) {
      return;
    }

    try {
      await dataProvider.uploadShippingDocument(
        shippingId,
        documentTypeId,
        file,
        userId,
        enquiryId
      );
      notify('Document uploaded successfully', { type: 'success' });

      if (!shippingId) {
        refresh();
      }
    } catch (error) {
      notify(`Error uploading document: ${error?.message ?? 'Unknown error'}`, {
        type: 'error',
      });
    }
  };

  const deleteDocument = async documentId => {
    return dataProvider.deleteShippingDocument(documentId);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ margin: '16px' }}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: '16px' }}>
      <DocumentUploader
        fetchDocuments={fetchDocuments}
        uploadDocument={uploadDocument}
        deleteDocument={deleteDocument}
        notify={notify}
        loadingDocuments={loading}
        documents={documents}
        isDisabled={isDisabled}
      />
    </Box>
  );
};

export default ShippingDocuments;
