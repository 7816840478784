import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useDataProvider,
} from 'react-admin';

import { getProducts } from 'supabase-connect';
import { PRODUCT_TYPE } from '../../../constants';
import { Input, InputAdornment, CircularProgress, Button } from '@mui/material';

import styles from './styles.module.css';
import debounce from 'lodash/debounce';
import UserSelector from './UserSelector';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateEnquiryToolbar from './createEnquiryToolbar';
import ConfigurationModal from '../ConfigurationModal';

// ProductCard component for displaying individual products
const ProductCard = ({ product, onSelect, selected }) => (
  <div className={`${styles.productCard} ${selected ? styles.selected : ''}`}>
    <div
      className={styles.productImage}
      style={{ backgroundImage: `url(${product?.image_url ?? ''})` }}
    />
    <div className={styles.productInfo}>
      <div className={styles.productName}>{product?.name ?? 'N/A'}</div>

      <div className={styles.productNumber}>
        {product?.product_number ?? 'N/A'}
      </div>

      <button
        type="button"
        className={styles.selectButton}
        onClick={() => onSelect(product)}
      >
        Select
      </button>
    </div>
  </div>
);

// Main ProductSelector component
const ProductSelector = ({
  selectedProducts,
  setSelectedProducts,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Fetch products on mount
  useEffect(() => {
    const fetchProducts = async () => {
      const { data: allProducts } = await getProducts();
      setAllProducts(allProducts ?? []);
    };
    fetchProducts();
  }, []);

  const openModal = product => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setModalOpen(false);
  };

  const handleSaveConfiguration = (productData, configurations) => {
    const newProduct = {
      ...productData,
      quantity: 1,
      selected_configurations: configurations,
      product_configurations: productData?.product_configurations ?? [],
    };
    setSelectedProducts(prevProducts => [...prevProducts, newProduct]);
    onChange([...selectedProducts, newProduct]);
  };

  const debouncedFetchProducts = useMemo(
    () =>
      debounce(term => {
        setIsLoading(true);
        const filteredProducts =
          allProducts?.filter(
            product =>
              product?.name?.toLowerCase()?.includes(term?.toLowerCase()) ||
              product?.product_number
                ?.toLowerCase()
                ?.includes(term?.toLowerCase())
          ) ?? [];
        setDisplayedProducts(filteredProducts.slice(0, 3));
        setIsLoading(false);
      }, 300),
    [allProducts]
  );

  const fetchProducts = useCallback(() => {
    debouncedFetchProducts(searchTerm);
  }, [debouncedFetchProducts, searchTerm]);

  useEffect(() => {
    if (allProducts) {
      fetchProducts(searchTerm);
    }
  }, [allProducts, searchTerm, fetchProducts]);

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleProductSelect = product => {
    if (
      product?.product_type === PRODUCT_TYPE?.VEHICLE &&
      product?.product_configurations
    ) {
      openModal(product);
    } else {
      const newProduct = { ...product, quantity: 1 };
      setSelectedProducts(prevProducts => [...prevProducts, newProduct]);
      onChange([...selectedProducts, newProduct]);
    }
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].quantity = quantity;
    setSelectedProducts(updatedProducts);
    onChange(updatedProducts);
  };

  const handleRemoveProduct = index => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);
    onChange(updatedProducts);
  };

  const handleClearAll = () => {
    setSelectedProducts([]);
    onChange([]);
  };

  return (
    <div className={styles.productSelectorContainer}>
      <div className={styles.productSelectionColumn}>
        <div className={styles.searchContainer}>
          <Input
            type="text"
            placeholder="Search for Products"
            value={searchTerm}
            onChange={handleSearchChange}
            className={styles.searchInput}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            fullWidth
          />
        </div>

        <div className={styles.productGrid}>
          {displayedProducts.map(product => (
            <ProductCard
              key={product?.id}
              product={product}
              onSelect={handleProductSelect}
              selected={selectedProducts.some(p => p?.id === product?.id)}
            />
          ))}
        </div>
        {isLoading && <CircularProgress />}
      </div>

      <div className={styles.selectedProductsColumn}>
        <div className={styles.selectedProductHeader}>
          <div className={styles.selectedProductTitle}>Selected Products</div>
          <Button onClick={handleClearAll} color="secondary" size="small">
            Clear All
          </Button>
        </div>
        <div className={styles.selectedProductContainer}>
          {selectedProducts.map((product, index) => (
            <div
              key={`${product?.id}-${index}`}
              className={styles.selectedProductCard}
            >
              <div className={styles.selectedProductInfo}>
                <div className={styles.selectedProductTop}>
                  <div className={styles.productName}>{product?.name}</div>
                  <Button
                    onClick={() => handleRemoveProduct(index)}
                    className={styles.removeButton}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
                <div className={styles.productNumber}>
                  {product?.product_number}
                </div>
                {product?.selected_configurations && (
                  <div className={styles.productConfigurations}>
                    {Object.entries(product.selected_configurations).map(
                      ([key, config]) => (
                        <span key={key}>{`${key}: ${config?.value}, `}</span>
                      )
                    )}
                  </div>
                )}
              </div>
              <Input
                type="number"
                value={product?.quantity ?? 1}
                onChange={e =>
                  handleQuantityChange(index, parseInt(e.target.value, 10))
                }
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                placeholder="Enter a quantity"
                startAdornment={
                  <InputAdornment position="start">Qty:</InputAdornment>
                }
                className={styles.quantityInput}
              />
            </div>
          ))}
        </div>
        <div className={styles.selectedProductSummary}>
          Total Products: {selectedProducts.length}
        </div>
      </div>

      {/* Modal to handle configuration selection */}
      {selectedProduct && (
        <ConfigurationModal
          open={isModalOpen}
          onClose={closeModal}
          product={selectedProduct}
          onSave={handleSaveConfiguration}
        />
      )}
    </div>
  );
};

export const EnquiryCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [submissionError, setSubmissionError] = useState(null);
  const [userId, setUserId] = useState(null);

  const handleProductChange = products => {
    setSelectedProducts(products);
    setSubmissionError(null);
  };

  const validateForm = () => {
    const validProducts = selectedProducts?.filter(p => p?.quantity > 0) ?? [];
    if (validProducts?.length === 0) {
      setSubmissionError(
        'At least one product with a quantity greater than 0 should be included in the enquiry.'
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async values => {
    if (!validateForm()) {
      return;
    }

    const validProducts = selectedProducts?.filter(p => p?.quantity > 0) ?? [];

    const transformedProducts = validProducts?.map(product => {
      const transformedConfigurations = product?.selected_configurations
        ? Object.entries(product.selected_configurations).reduce(
            (acc, [key, value]) => {
              acc[key] = value?.id;
              return acc;
            },
            {}
          )
        : {};

      return {
        ...product,
        selected_configurations: transformedConfigurations,
      };
    });

    const dataToSubmit = {
      ...values,
      user_id: userId,
      products: transformedProducts,
    };

    try {
      await dataProvider.create('enquiries', { data: dataToSubmit });
      notify('Enquiry created successfully', { type: 'success' });
      redirect('list', 'enquiries');
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'warning' });
      setSubmissionError('Failed to create the enquiry. Please try again.');
    }
  };

  return (
    <Create {...props}>
      <SimpleForm
        toolbar={
          <CreateEnquiryToolbar
            onSubmit={handleSubmit}
            selectedProducts={selectedProducts}
          />
        }
      >
        <div className={styles.formContainer}>
          <div className={styles.formFields}>
            <UserSelector value={userId} onChange={setUserId} />
          </div>

          <ProductSelector
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            onChange={handleProductChange}
          />

          {submissionError && (
            <div className={styles.errorMessage}>{submissionError}</div>
          )}

          {/* Hidden input to store the selected products data */}
          <TextInput
            source="products"
            style={{ display: 'none' }}
            value={JSON.stringify(selectedProducts)}
          />
        </div>
      </SimpleForm>
    </Create>
  );
};

export default EnquiryCreate;
