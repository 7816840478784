import { getOne } from '../resourceHandlers/getOneHandler';
import { getList } from '../resourceHandlers/getListHandler';
import { supabase } from '../utils/supabase';
import { handleEnquiry } from 'supabase-connect';
import { supabaseDataProvider } from 'ra-supabase';
import { ENQUIRY_STATUS, QUOTATION_STATUS } from '../constants';
import { sendNotification } from '../resourceHandlers/sendNotification';
import { checkUserRole, getUserDetails } from '../resourceHandlers/authHandler';

import userHandler from '../resourceHandlers/userHandler';
import productHandler from '../resourceHandlers/productHandler';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const baseDataProvider = supabaseDataProvider({
  instanceUrl: supabaseUrl,
  apiKey: supabaseAnonKey,
  supabase,
});

const ALLOWED_FILE_TYPES = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];

const validateFile = file => {
  const allowedMimeTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
  ];
  const maxSize = 5 * 1024 * 1024; // 5MB

  if (!file) {
    throw new Error('INVALID_FILE: File is required.');
  }

  const fileExtension = file.name.split('.').pop().toLowerCase();
  if (!ALLOWED_FILE_TYPES.includes(fileExtension)) {
    throw new Error(
      `INVALID_FILE_TYPE: Only ${ALLOWED_FILE_TYPES.join(
        ', '
      )} files are allowed.`
    );
  }

  if (!allowedMimeTypes.includes(file.type)) {
    throw new Error(
      `INVALID_FILE_TYPE: Invalid file type. Allowed types are ${ALLOWED_FILE_TYPES.join(
        ', '
      )}.`
    );
  }

  if (file.size > maxSize) {
    throw new Error('FILE_SIZE_EXCEEDED: File size must be less than 5MB.');
  }
};

const sanitizeFileName = name => {
  if (!name) {
    throw new Error('INVALID_FILE_NAME: File name is required.');
  }
  return name.replace(/[^a-zA-Z0-9._-]/g, '_');
};
const dataProvider = {
  ...baseDataProvider,

  getList: async (resource, params) => {
    return await getList(resource, params, baseDataProvider);
  },

  getOne: async (resource, params) => {
    return await getOne(resource, params, baseDataProvider);
  },

  create: async (resource, params) => {
    if (resource === 'users') {
      return userHandler.create(params);
    } else if (resource === 'products') {
      return productHandler.create(params);
    } else if (resource === 'enquiries') {
      return dataProvider.createEnquiry(params);
    }

    if (resource === 'quotations') {
      const { id: currentUserId, role } = await getUserDetails();

      if (!['ADMIN', 'SALES_MANAGER'].includes(role)) {
        return Promise.reject({
          message:
            "Access denied: You don't have permission to create quotations",
        });
      }

      const { data, error } = await supabase
        .from('quotations')
        .insert([{ ...params.data, created_by: currentUserId }])
        .select();

      if (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating quotation:', error);
        throw error;
      }

      return { data: data[0] };
    } else {
      const userRole = await checkUserRole();
      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        return Promise.reject({
          message: `Access denied: You don't have permission to create ${resource}`,
        });
      }
    }
    return baseDataProvider.create(resource, params);
  },

  update: async (resource, params) => {
    if (resource === 'users') {
      return userHandler.update(params);
    } else if (resource === 'products') {
      return productHandler.update(params);
    } else if (resource === 'enquiries') {
      return dataProvider.updateEnquiry(params);
    } else if (resource === 'quotations') {
      const userRole = await checkUserRole();
      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        return Promise.reject({
          message: `Access denied: You don't have permission to update this ${resource.slice(
            0,
            -1
          )}`,
        });
      }

      const { data, error } = await supabase
        .from(resource)
        .update(params.data)
        .eq('id', params.id)
        .select();

      if (error) {
        // eslint-disable-next-line no-console
        console.error(`Error updating ${resource.slice(0, -1)}:`, error);
        throw error;
      }

      return { data: data[0] };
    } else {
      const userRole = await checkUserRole();
      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        return Promise.reject({
          message: `Access denied: You don't have permission to update this ${resource.slice(
            0,
            -1
          )}`,
        });
      }
    }
    return baseDataProvider.update(resource, params);
  },

  createEnquiry: async params => {
    const currentUserId = params?.data?.user_id;

    if (!currentUserId) {
      throw new Error('Unable to process request: User ID must be specified.');
    }

    try {
      const enquiry = await handleEnquiry(params.data, params?.data?.user_id);

      return {
        data: {
          id: enquiry?.id,
          ...enquiry,
        },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating enquiry:', error);
      throw error;
    }
  },

  updateEnquiry: async params => {
    const { id: currentUserId, role } = await getUserDetails();

    const data = await handleEnquiry(
      { ...params.data, id: params.id },
      currentUserId,
      role
    );
    return { data };
  },

  updateEnquiryTracking: async (
    enquiryId,
    trackingUrl,
    trackingInformation
  ) => {
    const { data, error } = await supabase?.rpc('update_enquiry_tracking', {
      _enquiry_id: enquiryId,
      _tracking_url: trackingUrl,
      _tracking_information: trackingInformation,
    });
    if (error) throw error;
    return { data: data[0] };
  },

  generateQuotation: async ({ enquiryId, products, currencyId }) => {
    try {
      const {
        data: { user: currentUser },
      } = await supabase?.auth.getUser();

      // Calculate total amount
      const totalAmount = products.reduce(
        (sum, p) => sum + p.price * p.quantity,
        0
      );

      // Start a Supabase transaction
      const { data, error } = await supabase
        .from('quotations')
        .insert({
          enquiry_id: enquiryId,
          created_by: currentUser.id,
          quotation_amount: totalAmount,
          currency_id: currencyId,
          status: QUOTATION_STATUS?.PENDING_REVIEW,
        })
        .select()
        .single();

      if (error) {
        throw error;
      }

      const quotationId = data.id;

      // Insert quotation items and their configurations
      for (const product of products) {
        const { data: insertedProduct, error: itemError } = await supabase
          .from('quotation_products')
          .insert({
            quotation_id: quotationId,
            product_id: product?.isCustom ? null : product?.product_id,
            quantity: product?.quantity,
            price: product?.price,
            is_custom: product?.isCustom || false,
            product_description: product?.isCustom
              ? product?.product_description
              : null,
            configuration_id: product?.configuration_id, // Add this line
          })
          .select()
          .single();

        if (itemError) {
          throw itemError;
        }

        // Insert configuration options for this product
        if (
          product.configuration_options &&
          product.configuration_options.length > 0
        ) {
          const configOptionsInsert = product.configuration_options.map(
            optionId => ({
              quotation_product_id: insertedProduct.id,
              configuration_option_id: optionId,
            })
          );

          const { error: configError } = await supabase
            .from('quotation_configurations')
            .insert(configOptionsInsert);

          if (configError) {
            throw configError;
          }
        }
      }

      // Update enquiry status
      const { error: enquiryError } = await supabase
        .from('enquiries')
        .update({
          status: ENQUIRY_STATUS?.QUOTATION_GENERATED,
          quotation_id: quotationId,
        })
        .eq('id', enquiryId);

      if (enquiryError) {
        throw enquiryError;
      }

      // Fetch the complete quotation data
      const { data: quotationData, error: fetchError } = await supabase
        .from('quotations')
        .select(
          `
          *,
          items:quotation_products(*),
          quotation_number
        `
        )
        .eq('id', quotationId)
        .single();

      if (fetchError) {
        throw fetchError;
      }

      const {
        data: { user_id, enquiry_number, status: enquiryStatus },
      } = await supabase
        .from('enquiries')
        .select('user_id, enquiry_number, status')
        .eq('id', enquiryId)
        .single();

      const notificationTitle = 'Quotation Generated';
      const notificationBody = `Quotation ${quotationData?.quotation_number} has been generated for your enquiry ${enquiry_number}.`;

      await sendNotification(
        supabase,
        user_id,
        enquiryId,
        enquiryStatus,
        notificationTitle,
        notificationBody
      );

      return { data: quotationData };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in generateQuotation:', error);
      throw error;
    }
  },

  generateInvoice: async (quotationId, billTo, shipTo, currencyCode) => {
    let invoiceData;

    try {
      const { data: generatedInvoiceData, error: invoiceError } =
        await supabase?.rpc('generate_invoice', {
          p_quotation_id: quotationId,
          p_bill_to: billTo,
          p_ship_to: shipTo,
        });

      if (invoiceError) {
        throw new Error(`Error generating invoice: ${invoiceError.message}`);
      }

      invoiceData = generatedInvoiceData;

      const formData = new FormData();
      formData.append('action', 'generate_pdf');
      formData.append('bill_to', billTo);
      formData.append('ship_to', shipTo);
      formData.append('invoice_data', JSON.stringify(invoiceData));
      formData.append('currency_code', currencyCode);

      const { data: pdfData, error: pdfError } =
        await supabase?.functions?.invoke('generate-pdf', {
          body: formData,
        });

      if (pdfError) {
        throw new Error(`Error generating PDF: ${pdfError?.message}`);
      }

      const { pdf_url } = pdfData;

      const { error: updateError } = await supabase
        .from('invoices')
        .update({ pdf_url })
        .eq('id', invoiceData?.id);

      if (updateError) {
        throw new Error(
          `Error updating invoice with PDF URL: ${updateError.message}`
        );
      }

      const { data: enquiryData, error: enquiryError } = await supabase
        .from('enquiries')
        .select('user_id, id, enquiry_number, status')
        .eq('quotation_id', quotationId)
        .single();

      if (enquiryError) {
        throw new Error(
          `Error fetching enquiry details: ${enquiryError.message}`
        );
      }

      const {
        user_id,
        id: enquiryId,
        enquiry_number,
        status: enquiryStatus,
      } = enquiryData;

      const notificationTitle = 'Invoice Generated';
      const notificationBody = `Invoice ${invoiceData?.invoice_number} has been generated for your enquiry ${enquiry_number}.`;

      await sendNotification(
        supabase,
        user_id,
        enquiryId,
        enquiryStatus,
        notificationTitle,
        notificationBody
      );

      return {
        data: {
          ...invoiceData,
          pdf_url,
        },
      };
    } catch (error) {
      if (invoiceData) {
        // Revert the quotation and enquiry status
        await supabase
          .from('quotations')
          .update({ invoice_id: null })
          .eq('id', quotationId);

        // Delete the invoice if it was created
        await supabase.from('invoices').delete().eq('id', invoiceData?.id);

        await supabase
          .from('enquiries')
          .update({ status: ENQUIRY_STATUS?.INVOICE_PENDING })
          .eq('quotation_id', quotationId);
      }

      throw new Error(`Failed to generate invoice: ${error.message}`);
    }
  },

  uploadInvoice: async (
    quotationId,
    invoiceNumber,
    invoiceAmount,
    billTo,
    shipTo,
    file
  ) => {
    let invoiceData;

    try {
      const { data: generatedInvoiceData, error: uploadError } =
        await supabase?.rpc('generate_invoice', {
          p_quotation_id: quotationId,
          p_bill_to: billTo,
          p_ship_to: shipTo,
        });

      if (uploadError) {
        throw new Error(`Error generating invoice: ${uploadError.message}`);
      }

      invoiceData = generatedInvoiceData;

      const formData = new FormData();
      formData.append('action', 'upload');
      formData.append('file', file);
      formData.append('invoice_data', JSON.stringify(invoiceData));
      formData.append('invoice_number', invoiceNumber);
      formData.append('invoice_amount', invoiceAmount);
      formData.append('bill_to', billTo);
      formData.append('ship_to', shipTo);

      const { data: pdfData, error: pdfError } =
        await supabase?.functions?.invoke('generate-pdf', {
          body: formData,
        });

      if (pdfError) {
        throw new Error(`Error uploading PDF: ${pdfError?.message}`);
      }

      const { pdf_url } = pdfData;

      const { error: invoiceError } = await supabase
        .from('invoices')
        .update({
          invoice_number: invoiceNumber,
          invoice_amount: parseFloat(invoiceAmount),
          pdf_url,
        })
        .eq('id', invoiceData?.id);

      if (invoiceError) {
        throw new Error(
          `Error creating invoice record: ${invoiceError?.message}`
        );
      }

      const { data: enquiryData, error: enquiryError } = await supabase
        .from('enquiries')
        .select('user_id, id, enquiry_number, status')
        .eq('quotation_id', quotationId)
        .single();

      if (enquiryError) {
        throw new Error(
          `Error fetching enquiry details: ${enquiryError.message}`
        );
      }

      const {
        user_id,
        id: enquiryId,
        enquiry_number,
        status: enquiryStatus,
      } = enquiryData;

      const notificationTitle = 'Invoice Generated';
      const notificationBody = `Invoice ${invoiceData?.invoice_number} has been generated for your enquiry ${enquiry_number}.`;

      await sendNotification(
        supabase,
        user_id,
        enquiryId,
        enquiryStatus,
        notificationTitle,
        notificationBody
      );

      return {
        data: {
          ...invoiceData,
          pdf_url,
        },
      };
    } catch (error) {
      if (invoiceData) {
        // Revert the quotation and enquiry status
        await supabase
          .from('quotations')
          .update({ invoice_id: null })
          .eq('id', quotationId);

        // Delete the invoice if it was created
        await supabase.from('invoices').delete().eq('id', invoiceData.id);

        await supabase
          .from('enquiries')
          .update({ status: ENQUIRY_STATUS?.INVOICE_PENDING })
          .eq('quotation_id', quotationId);
      }

      throw new Error(`Failed to upload invoice: ${error.message}`);
    }
  },

  confirmAdvancedPayment: async paymentId => {
    const { data, error } = await supabase?.rpc('confirm_advanced_payment', {
      p_payment_id: paymentId,
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  },

  toggleStatus: async (resource, params) => {
    if (resource === 'products') {
      return productHandler?.toggleStatus(params);
    }
    return Promise.reject(`toggleStatus not implemented for ${resource}`);
  },

  insertOrUpdateShippingDetails: async params => {
    const { user_id, enquiry_number, enquiry_status } = await params?.data;

    const { data, error } = await supabase.rpc(
      'insert_or_update_shipping_details',
      {
        p_enquiry_id: params.data.enquiry_id,
        p_tracking_url: params.data.tracking_url,
        p_tracking_information: params.data.tracking_information,
      }
    );

    if (error) {
      throw new Error(error.message);
    }

    const notificationTitle = 'Tracking Details Updated';
    const notificationBody = `Shipment tracking details for Enquiry ${enquiry_number} is now available.`;

    await sendNotification(
      supabase,
      user_id,
      params.data.enquiry_id,
      enquiry_status,
      notificationTitle,
      notificationBody
    );

    return { data: { id: data, ...params.data } };
  },

  insertShippingImage: async params => {
    const file = params.data.image;
    const enquiryId = params.data.enquiry_id;

    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${enquiryId}/${fileName}`;

    try {
      const { error: uploadError } = await supabase.storage
        .from('shipping_images')
        .upload(filePath, file, { upsert: true });

      if (uploadError) throw uploadError;

      const {
        data: { publicUrl },
        error: urlError,
      } = supabase.storage.from('shipping_images').getPublicUrl(filePath);

      if (urlError) throw urlError;

      const { data, error } = await supabase.rpc('insert_shipping_image', {
        p_enquiry_id: enquiryId,
        p_file_url: publicUrl,
      });

      if (error) throw error;

      return {
        data: {
          id: data,
          enquiry_id: enquiryId,
          image_url: publicUrl,
        },
      };
    } catch (error) {
      throw new Error(error.message);
    }
  },

  deleteShippingImage: async (imageId, enquiryId) => {
    const { data: imageUrl, error: dbError } = await supabase.rpc(
      'delete_shipping_image',
      { p_image_id: imageId }
    );

    if (dbError) throw dbError;

    const fileName = imageUrl.split('/').pop();
    const { error: storageError } = await supabase.storage
      .from('shipping_images')
      .remove([`${enquiryId}/${fileName}`]);

    if (storageError) throw storageError;
  },

  uploadShippingDocument: async (
    shippingDetailId,
    documentTypeId,
    file,
    userId,
    enquiryId
  ) => {
    try {
      let finalShippingDetailId = shippingDetailId;

      if (!finalShippingDetailId || typeof finalShippingDetailId !== 'string') {
        if (!userId || !enquiryId) {
          throw new Error(
            'MISSING_IDS: If shippingDetailId is not provided, both userId and enquiryId are required.'
          );
        }

        const { data: shippingDetailData, error: rpcError } =
          await supabase.rpc('create_or_get_shipping_detail', {
            p_user_id: userId,
            p_enquiry_id: enquiryId,
          });

        if (rpcError) {
          throw new Error(
            `RPC_ERROR: ${
              rpcError?.message ?? 'Failed to create or fetch shipping detail'
            }`
          );
        }

        finalShippingDetailId = shippingDetailData;
      }

      if (!documentTypeId || typeof documentTypeId !== 'string') {
        throw new Error(
          'INVALID_DOCUMENT_TYPE_ID: Document type ID is required and must be a valid UUID.'
        );
      }

      // Validate that the documentTypeId exists in the shipping_document_types table
      const { data: documentTypeData, error: documentTypeError } =
        await supabase
          .from('shipping_document_types')
          .select('id')
          .eq('id', documentTypeId)
          .single();

      if (documentTypeError || !documentTypeData) {
        throw new Error(
          'INVALID_DOCUMENT_TYPE_ID: The provided document type ID does not exist.'
        );
      }

      validateFile(file);

      const fileName = `${finalShippingDetailId.slice(-2)}_${Date.now()
        .toString()
        .slice(-2)}_${sanitizeFileName(file?.name ?? 'unknown')}`;

      const { error: uploadError } = await supabase.storage
        .from('shipping_documents')
        .upload(fileName, file);

      if (uploadError) {
        throw new Error(
          `FILE_UPLOAD_ERROR: ${uploadError?.message ?? 'Unknown error'}`
        );
      }

      const { data: urlData } = await supabase.storage
        .from('shipping_documents')
        .getPublicUrl(fileName);

      const documentUrl = urlData?.publicUrl;

      if (!documentUrl) {
        throw new Error('Failed to generate document URL');
      }

      const { data: documentData, error: documentError } = await supabase.rpc(
        'add_shipping_document',
        {
          p_shipping_detail_id: finalShippingDetailId,
          p_document_type_id: documentTypeId,
          p_document_url: documentUrl,
        }
      );

      if (documentError) {
        throw new Error(
          `DATABASE_ERROR: ${documentError?.message ?? 'Unknown error'}`
        );
      }

      return { data: { id: documentData, documentUrl } };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading shipping document:', error);
      throw new Error(
        `Failed to upload document: ${error?.message ?? 'Unknown error'}`
      );
    }
  },

  getShippingDocuments: async (shippingDetailId, documentTypeId = null) => {
    try {
      if (!shippingDetailId || typeof shippingDetailId !== 'string') {
        throw new Error(
          'INVALID_SHIPPING_DETAIL_ID: Shipping detail ID is required and must be a valid UUID.'
        );
      }

      let query = supabase
        .from('shipping_documents')
        .select(
          `
          *,
          document_type:shipping_document_types(id, value, label)
        `
        )
        .eq('shipping_detail_id', shippingDetailId);

      if (documentTypeId) {
        if (typeof documentTypeId !== 'string') {
          throw new Error(
            'INVALID_DOCUMENT_TYPE_ID: Document type ID must be a valid UUID.'
          );
        }

        query = query.eq('document_type_id', documentTypeId);
      }

      const { data, error } = await query;

      if (error) {
        throw new Error(`DATABASE_ERROR: ${error?.message ?? 'Unknown error'}`);
      }

      // Transform the data to match the expected format
      const transformedData = data.map(doc => ({
        ...doc,
        document_type: doc.document_type.value,
        document_type_label: doc.document_type.label,
      }));

      return { data: transformedData };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching shipping documents:', error);
      throw new Error(
        `Failed to fetch documents: ${error?.message ?? 'Unknown error'}`
      );
    }
  },

  deleteShippingDocument: async documentId => {
    try {
      if (!documentId || typeof documentId !== 'string') {
        throw new Error(
          'INVALID_DOCUMENT_ID: Document ID is required and must be a valid UUID.'
        );
      }

      const { data: documentUrl, error: dbError } = await supabase.rpc(
        'delete_shipping_document',
        {
          p_document_id: documentId,
        }
      );

      if (dbError) {
        throw new Error(
          `DATABASE_ERROR: ${dbError?.message ?? 'Unknown error'}`
        );
      }

      if (!documentUrl) {
        return {
          data: {
            success: true,
            message: 'Document not found or already deleted',
          },
        };
      }

      const fileName = documentUrl?.split('/')?.pop();
      if (!fileName) {
        throw new Error('Failed to extract file name from document URL');
      }

      const { error: storageError } = await supabase.storage
        .from('shipping_documents')
        .remove([fileName]);

      if (storageError) {
        throw new Error(
          `STORAGE_ERROR: ${storageError?.message ?? 'Unknown error'}`
        );
      }

      return {
        data: { success: true, message: 'Document deleted successfully' },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error deleting shipping document:', error);
      throw new Error(
        `Failed to delete document: ${error?.message ?? 'Unknown error'}`
      );
    }
  },

  updateEnquiryStatus: async (enquiryId, newStatus) => {
    const { data, error } = await supabase.rpc('update_enquiry_status', {
      p_enquiry_id: enquiryId,
      p_new_status: newStatus,
    });

    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating enquiry status:', error);
      throw error;
    }

    return { data };
  },

  updateInvoiceStatus: async (invoiceId, newStatus) => {
    const { error } = await supabase.rpc('update_invoice_status', {
      p_invoice_id: invoiceId,
      p_new_status: newStatus,
    });

    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating invoice status:', error);
      throw error;
    }
  },

  getInvoiceForEnquiry: async enquiryId => {
    const { data, error } = await supabase.rpc('get_invoice_for_enquiry', {
      p_enquiry_id: enquiryId,
    });

    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching invoice for enquiry:', error);
      throw error;
    }

    if (!data || data.length === 0) {
      return null;
    }

    return data[0];
  },
};

export default dataProvider;
