import React, { useState, useCallback, useEffect } from 'react';
import { useNotify, useRefresh, useDataProvider } from 'react-admin';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Box,
} from '@mui/material';
import { ENQUIRY_STATUS, INVOICE_STATUS } from '../../../constants';
import { EnquiryStatus, formatStatusName } from '../../../common/helpers';

const UPDATABLE_STATUSES = [
  ENQUIRY_STATUS?.DELIVERED_PENDING_PAYMENT,
  ENQUIRY_STATUS?.FINAL_PAYMENT_INITIATED,
  ENQUIRY_STATUS?.COMPLETED,
];

const EnquiryStatusUpdate = ({ record }) => {
  const [newStatus, setNewStatus] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const fetchInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedInvoice = await dataProvider.getInvoiceForEnquiry(record.id);
      setInvoice(fetchedInvoice);
    } catch (error) {
      notify(`Error fetching invoice: ${error.message}`, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }, [dataProvider, record.id, notify]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  const handleStatusUpdate = () => {
    if (newStatus) {
      setOpenConfirmation(true);
    }
  };

  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const handleConfirmSubmit = async () => {
    try {
      await dataProvider.updateEnquiryStatus(record.id, newStatus);

      if (
        newStatus === ENQUIRY_STATUS.FINAL_PAYMENT_INITIATED &&
        invoice?.id &&
        INVOICE_STATUS.PAID !== invoice?.status
      ) {
        await dataProvider.updateInvoiceStatus(
          invoice?.id,
          INVOICE_STATUS?.PAID
        );
        notify('Enquiry and Invoice statuses updated successfully', {
          type: 'success',
        });
      } else {
        notify('Enquiry status updated successfully', { type: 'success' });
      }

      refresh();
      handleCloseConfirmation();
    } catch (error) {
      notify(`Error updating status: ${error.message}`, { type: 'error' });
    }
  };

  const isStatusUpdatable = record.status !== ENQUIRY_STATUS.COMPLETED;

  const getAvailableStatuses = () =>
    UPDATABLE_STATUSES.filter(
      status =>
        UPDATABLE_STATUSES.indexOf(status) >
        UPDATABLE_STATUSES.indexOf(record.status)
    );

  const getSelectLabel = () => {
    return isStatusUpdatable ? 'Next Status' : 'Current Status';
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle2">Status</Typography>
        <EnquiryStatus status={record?.status} />
      </Box>

      <Typography
        variant="body2"
        gutterBottom
        color={isStatusUpdatable ? 'textSecondary' : 'green'}
      >
        {isStatusUpdatable
          ? 'Select the next stage in the enquiry process:'
          : 'This enquiry has been completed and cannot be updated further.'}
      </Typography>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id="status-select-label">{getSelectLabel()}</InputLabel>
            <Select
              labelId="status-select-label"
              value={isStatusUpdatable ? newStatus : record.status}
              onChange={e => setNewStatus(e.target.value)}
              label={getSelectLabel()}
              disabled={!isStatusUpdatable}
            >
              {isStatusUpdatable ? (
                getAvailableStatuses().map(status => (
                  <MenuItem key={status} value={status}>
                    {formatStatusName(status)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={record.status}>
                  {formatStatusName(record.status)}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStatusUpdate}
            disabled={!newStatus || !isStatusUpdatable}
          >
            Update Status
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        sx={{ position: 'absolute', bottom: 0 }}
      >
        <DialogTitle>Confirm Status Update</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to update the status to{' '}
            {formatStatusName(newStatus)}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Cancel</Button>
          <Button onClick={handleConfirmSubmit} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EnquiryStatusUpdate;
