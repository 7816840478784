import React, { useState, useRef, useEffect } from 'react';
import { ENQUIRY_STATUS, INVOICE_STATUS } from '../../constants';
import { useNotify, useRefresh, useDataProvider } from 'react-admin';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  Fade,
  Divider,
} from '@mui/material';

import ShippingDocuments from '../ShippingDocuments';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import styles from './styles.module.css';

const ShippingDetailsUpload = ({
  userId,
  enquiryId,
  invoiceId,
  shippingId,
  enquiryNumber,
  enquiryStatus,
  currentTrackingUrl,
  currentTrackingInfo,
  shippingImages,
}) => {
  const [trackingUrl, setTrackingUrl] = useState(currentTrackingUrl || '');
  const [trackingInfo, setTrackingInfo] = useState(currentTrackingInfo || '');
  const [loadingTracking, setLoadingTracking] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const fileInputRef = useRef(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const isEnquiryCompleted = enquiryStatus === ENQUIRY_STATUS.COMPLETED;

  useEffect(() => {
    const isFormDirty =
      trackingUrl !== currentTrackingUrl ||
      trackingInfo !== currentTrackingInfo;
    setIsDirty(isFormDirty);
  }, [trackingUrl, trackingInfo, currentTrackingUrl, currentTrackingInfo]);

  const handleShippingDetailsSubmit = async event => {
    event.preventDefault();
    if (isEnquiryCompleted) return;
    setLoadingTracking(true);

    try {
      await dataProvider?.insertOrUpdateShippingDetails?.({
        data: {
          user_id: userId,
          enquiry_number: enquiryNumber,
          enquiry_status: enquiryStatus,
          enquiry_id: enquiryId,
          tracking_url: trackingUrl,
          tracking_information: trackingInfo,
        },
      });

      notify?.('Shipping details uploaded successfully!', { type: 'info' });
      refresh?.();
    } catch (error) {
      notify?.('Error: ' + error?.message, { type: 'error' });
    } finally {
      setLoadingTracking(false);
    }
  };

  const handleFileSelect = event => {
    if (isEnquiryCompleted) {
      return;
    }

    const files = Array.from(event?.target?.files || []);

    if (files.length > 5) {
      notify?.('You can only select up to 5 images', { type: 'warning' });
      setSelectedFiles([]);
      if (fileInputRef?.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    const validFiles = files.filter(file => {
      if (file?.size > 2 * 1024 * 1024) {
        notify?.(`File ${file?.name} is larger than 2MB`, { type: 'warning' });
        return false;
      }
      return true;
    });

    setSelectedFiles(validFiles);

    if (validFiles.length < files.length) {
      notify?.('Some files were not selected due to size restrictions', {
        type: 'warning',
      });
    }
  };

  const handleImageUpload = async () => {
    if (isEnquiryCompleted || selectedFiles?.length === 0) {
      return;
    }

    setLoadingImage(true);

    try {
      await Promise.all(
        selectedFiles?.map(file =>
          dataProvider?.insertShippingImage?.({
            data: {
              enquiry_id: enquiryId,
              image: file,
            },
          })
        )
      );

      notify?.('Images uploaded successfully!', { type: 'info' });
      refresh?.();
      setSelectedFiles([]);
      if (fileInputRef?.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      notify?.('Error: ' + error?.message, { type: 'error' });
    } finally {
      setLoadingImage(false);
    }
  };

  const handleImageDelete = async (imageId, enquiryId) => {
    if (isEnquiryCompleted) {
      return;
    }

    try {
      await dataProvider?.deleteShippingImage?.(imageId, enquiryId);
      notify?.('Image deleted successfully!', { type: 'info' });
      refresh?.();
    } catch (error) {
      notify?.('Error: ' + error?.message, { type: 'error' });
    }
  };

  const handleMarkDeliveredPendingPayment = async () => {
    if (isEnquiryCompleted) {
      return;
    }

    try {
      await dataProvider.updateEnquiryStatus(
        enquiryId,
        ENQUIRY_STATUS.DELIVERED_PENDING_PAYMENT
      );

      if (invoiceId) {
        await dataProvider.updateInvoiceStatus(invoiceId, INVOICE_STATUS.PAID);
        notify(
          'Enquiry marked as Delivered Pending Payment and Invoice status updated',
          {
            type: 'success',
          }
        );
      } else {
        notify('Enquiry marked as Delivered Pending Payment', {
          type: 'success',
        });
      }

      refresh();
    } catch (error) {
      notify('Error: ' + error.message, { type: 'error' });
    }
  };

  return (
    <>
      <Card className={styles?.cardContainer}>
        <Typography variant="h6" className={styles?.cardTitle}>
          Shipping & Loading
        </Typography>

        <ShippingDocuments
          shippingId={shippingId}
          userId={userId}
          enquiryId={enquiryId}
          isDisabled={isEnquiryCompleted}
        />

        <Divider
          sx={{
            my: 4,
            borderStyle: 'dashed',
            borderColor: 'rgba(0, 0, 0, 0.3)',
            borderBottomWidth: 2,
            width: 'calc(100% - 32px)',
            marginLeft: '16px',
            marginRight: '16px',
          }}
        />

        <CardContent sx={{ padding: '0 16px !important' }}>
          <Box mb={3}>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ marginTop: '16px' }}
            >
              Update Tracking Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Tracking URL"
                  value={trackingUrl}
                  onChange={e => setTrackingUrl(e?.target?.value)}
                  placeholder="Enter Tracking URL"
                  disabled={isEnquiryCompleted}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Tracking Information"
                  value={trackingInfo}
                  onChange={e => setTrackingInfo(e?.target?.value)}
                  placeholder="Enter Tracking Information"
                  multiline
                  rows={4}
                  disabled={isEnquiryCompleted}
                />
              </Grid>
            </Grid>

            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  textTransform: 'none',
                }}
                onClick={handleShippingDetailsSubmit}
                disabled={loadingTracking || !isDirty || isEnquiryCompleted}
              >
                {loadingTracking ? 'Updating...' : 'Update Tracking'}
              </Button>
            </Box>
          </Box>
        </CardContent>

        <Divider
          sx={{
            my: 4,
            borderStyle: 'dashed',
            borderColor: 'rgba(0, 0, 0, 0.3)',
            borderBottomWidth: 2,
            width: 'calc(100% - 32px)',
            marginLeft: '16px',
            marginRight: '16px',
          }}
        />

        <CardContent>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Shipping & Loading Photos
            </Typography>

            <input
              accept=".png, .jpg, .jpeg"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              multiple
              onChange={handleFileSelect}
              ref={fileInputRef}
              disabled={isEnquiryCompleted}
            />

            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={loadingImage || isEnquiryCompleted}
                sx={{
                  gap: '8px',
                }}
                style={{
                  textTransform: 'none',
                }}
              >
                Add Photos
              </Button>
            </label>

            {selectedFiles?.length > 0 && (
              <Box mt={2}>
                <Typography variant="body2">
                  {selectedFiles?.length} file(s) selected
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleImageUpload}
                  disabled={loadingImage || isEnquiryCompleted}
                  sx={{ mt: 1 }}
                >
                  {loadingImage ? 'Uploading...' : 'Upload Selected Images'}
                </Button>
              </Box>
            )}
          </Box>

          {shippingImages?.length > 0 && (
            <Box mt={3}>
              <Grid container spacing={2}>
                {shippingImages?.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Box
                      sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: 1,
                        width: 250,
                        height: 150,
                        margin: 'auto',
                        '&:hover .deleteButton': {
                          transform: 'translateY(0)',
                        },
                      }}
                    >
                      <img
                        src={image?.image_url}
                        alt={`Shipping Proof ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                      <Fade in={true}>
                        <Box
                          className="deleteButton"
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px',
                            transform: 'translateY(100%)',
                            transition: 'transform 0.3s ease-in-out',
                          }}
                        >
                          <Button
                            href={image?.image_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="text"
                            size="small"
                            sx={{ color: 'white' }}
                          >
                            View
                          </Button>

                          <Button
                            onClick={() =>
                              handleImageDelete(image?.id, enquiryId)
                            }
                            color="error"
                            startIcon={<DeleteIcon />}
                            size="small"
                            disabled={isEnquiryCompleted}
                            sx={{
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.8)',
                                color: 'white',
                              },
                              '&:active': {
                                backgroundColor: 'rgba(255, 0, 0, 1)',
                              },
                              transition: 'background-color 0.3s ease',
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Fade>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>

      <Card className={styles.cardContainer}>
        <Typography variant="h6" className={styles?.cardTitle}>
          Update Shipping Status
        </Typography>

        <CardContent>
          <Button
            variant="contained"
            color="secondary"
            style={{
              textTransform: 'none',
            }}
            onClick={handleMarkDeliveredPendingPayment}
            disabled={
              enquiryStatus !== 'ORDER_IN_PROGRESS' || isEnquiryCompleted
            }
          >
            Mark Delivered Pending Payment
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default ShippingDetailsUpload;
