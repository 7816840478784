import { supabase } from '../utils/supabase';
import { checkUserRole } from './authHandler';

const userHandler = {
  create: async params => {
    try {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const accessToken = sessionData?.session?.access_token;
      if (!accessToken) throw new Error('No access token available');

      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can create new users');
      }

      const {
        email,
        password,
        role = 'CUSTOMER',
        default_currency,
      } = params.data;

      const {
        data: { user: newUser },
        error: createUserError,
      } = await supabase.functions.invoke('create-user', {
        body: JSON.stringify({ email, password, role }),
      });

      if (createUserError)
        throw new Error(`Error creating user: ${createUserError.message}`);

      const { error: updateRoleError } = await supabase
        .from('user_details')
        .update({ role, default_currency: default_currency?.id })
        .eq('id', newUser.id);

      if (updateRoleError) throw updateRoleError;

      return {
        data: {
          id: newUser.id,
          email: newUser.email,
          role,
          default_currency,
        },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in create function for users:', error);
      throw new Error('User creation failed: ' + error.message);
    }
  },

  update: async params => {
    try {
      const { error: currentUserError } = await supabase.auth.getUser();
      if (currentUserError) {
        throw new Error(`Authentication failed: ${currentUserError.message}`);
      }

      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can update user details');
      }

      const { data: currentUserData, error: fetchError } = await supabase
        .from('user_details')
        .select('*')
        .eq('id', params?.data?.id)
        .single();

      if (fetchError) {
        throw new Error(`Failed to fetch user data: ${fetchError.message}`);
      }

      const changedFields = {};
      const fieldsToCheck = ['role', 'default_currency', 'email'];

      fieldsToCheck.forEach(field => {
        if (field === 'default_currency') {
          const newCurrency = params.data?.selectedCurrency?.id;
          if (newCurrency !== currentUserData?.default_currency) {
            changedFields[field] = newCurrency ?? null;
          }
        } else if (params.data?.[field] !== currentUserData?.[field]) {
          changedFields[field] = params.data?.[field] ?? null;
        }
      });

      if (Object.keys(changedFields).length === 0) {
        return { data: { id: params?.data?.id, ...params.data } };
      }

      if (changedFields?.email) {
        const { error: emailUpdateError } = await supabase.functions.invoke(
          'update-user-email',
          {
            body: JSON.stringify({
              userId: params?.data?.id,
              newEmail: changedFields.email,
            }),
          }
        );

        if (emailUpdateError)
          throw new Error(
            `Failed to update email in auth.users table: ${emailUpdateError.message}`
          );
      }

      const { data: updatedUserData, error: updateError } = await supabase
        .from('user_details')
        .update(changedFields)
        .eq('id', params?.data?.id)
        .select();

      if (updateError)
        throw new Error(
          `Failed to update user details: ${updateError.message}`
        );

      return {
        data: { id: params?.data?.id, ...(updatedUserData?.[0] ?? {}) },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in update for users:', error);
      throw error;
    }
  },

  delete: async params => {
    try {
      const { error: currentUserError } = await supabase.auth.getUser();
      if (currentUserError) throw currentUserError;

      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can delete users');
      }

      const { data, error: deleteError } = await supabase.rpc('delete_user', {
        user_id: params.id,
      });
      if (deleteError) throw deleteError;

      return { data: { id: data.id } };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in delete for users:', error);
      throw error;
    }
  },

  deleteMany: async params => {
    try {
      const { error: currentUserError } = await supabase.auth.getUser();
      if (currentUserError) throw currentUserError;

      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can delete users');
      }

      const results = await Promise.all(
        params.ids.map(id =>
          supabase.rpc('delete_user', {
            user_id: id,
          })
        )
      );

      const failedDeletions = results.filter(({ error }) => error);
      if (failedDeletions.length > 0) {
        // eslint-disable-next-line no-console
        console.error('Some deletions failed:', failedDeletions);
        throw new Error('Some users could not be deleted');
      }

      return { data: params.ids };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in deleteMany for users:', error);
      throw error;
    }
  },

  resetPassword: async (userId, newPassword) => {
    try {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();

      if (sessionError) throw sessionError;

      const accessToken = sessionData?.session?.access_token;
      if (!accessToken) throw new Error('No access token available');

      const userRole = await checkUserRole();
      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can reset passwords');
      }

      const { error: resetError } = await supabase.functions.invoke(
        'reset-password',
        {
          body: JSON.stringify({ userId, newPassword }),
        }
      );

      if (resetError) throw resetError;

      return { success: true };
    } catch (error) {
      throw new Error('Password reset failed: ' + error.message);
    }
  },
};

export default userHandler;
