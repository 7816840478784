import React from 'react';
import { DATE_FORMAT_OPTIONS } from '../../../constants';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
} from 'react-admin';
import { formatCurrency } from '../../../common/helpers';
import { FormatStatusField } from '../../../components/common-component/FormatStatusField/index.js';

import InvoiceFilter from './InvoiceFilter.js';

const InvoiceList = props => {
  return (
    <List
      {...props}
      resource="invoices"
      filters={<InvoiceFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="invoice_number" label="Invoice Number" />

        <FunctionField
          label="User Email"
          render={record =>
            record.quotation_id?.enquiries?.user_details?.email || 'N/A'
          }
        />

        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={DATE_FORMAT_OPTIONS}
        />

        <DateField
          source="updated_at"
          label="Updated At"
          showTime
          options={DATE_FORMAT_OPTIONS}
        />

        <FunctionField
          label="Amount"
          render={record => {
            const formattedAmount = formatCurrency(
              record?.invoice_amount,
              record?.quotation_id?.currency_id?.code
            );
            return formattedAmount;
          }}
        />

        <FormatStatusField source="status" />
      </Datagrid>
    </List>
  );
};

export default InvoiceList;
